import React, { useState } from 'react';
import { FlatList, RefreshControl } from 'react-native';
import { useInfiniteList, useSubscription } from '@refinedev/core';
import { getAllPagesData } from '../utils/data';
import useUser from '../hooks/useUser';
import { Box, Text } from '@gluestack-ui/themed';
import { PostCard } from '../components/layout/PostCard';

export default function Home() {
  const {
    data: posts,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isRefetching,
  } = useInfiniteList({
    resource: 'posts',
    sorters: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    pagination: {
      pageSize: 25,
    },
    meta: {
      select:
        '*, profiles(id, displayName, avatarUrl), postReactions(reaction, userId), comments(id, createdAt, comment, commentReactions(reaction, userId), profiles(id, displayName, avatarUrl))',
    },
  });

  return (
    <FlatList
      isLoading={isLoading}
      refreshing={isRefetching}
      onRefresh={refetch}
      contentContainerStyle={{ paddingVertical: 24, paddingHorizontal: 12 }}
      ItemSeparatorComponent={() => <Box h="$6" />}
      data={getAllPagesData(posts)}
      renderItem={({ item }) => (
        <PostCard key={item.id} item={item} refetch={refetch} />
      )}
    />
  );
}
