import {
  Box,
  Image,
  VStack,
  HStack,
  Icon,
  Heading,
  Text,
  Center,
  Pressable,
} from '@gluestack-ui/themed';
import Card from './Card';
import { UiButton } from '../ui';
import {
  HelpingHandIcon,
  HeartHandshakeIcon,
  RedoIcon,
  UserIcon,
} from 'lucide-react-native';

export const SwipeCard = ({
  item: { id, avatarUrl, displayName, canHelp, needHelp },
  onSkip,
  go,
}) => {
  return (
    <Card flex={1} w="$full" h="$full">
      <VStack w="$full" flex={1} position="relative">
        <UiButton
          size="xs"
          variant="outline"
          icon={UserIcon}
          title="Profile"
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 100 }}
          onPress={() => go({ to: `/profile/${id}` })}
        />
        <Image
          contentFit="contain"
          borderTopLeftRadius="$lg"
          borderTopRightRadius="$lg"
          w="$full"
          h="$72"
          mr="$3"
          mb="$3"
          source={{
            uri: avatarUrl,
          }}
        />
        <Center mb="$5">
          <Heading size="xl">{displayName}</Heading>
        </Center>
        <Box px="$3">
          <VStack mb="$5">
            <HStack alignItems="center" mb="$3">
              <Icon
                as={HelpingHandIcon}
                size="lg"
                mr="$3"
                color={'$success500'}
              />
              <Heading size="sm">{'Can Help With'}</Heading>
            </HStack>
            <Text>{canHelp}</Text>
          </VStack>
          <VStack>
            <HStack alignItems="center" mb="$3">
              <Icon
                as={HeartHandshakeIcon}
                size="lg"
                mr="$3"
                color={'$warning500'}
              />
              <Heading size="sm">{'Need Help With'}</Heading>
            </HStack>
            <Text>{needHelp}</Text>
          </VStack>
        </Box>
      </VStack>
      <Box
        w="$full"
        px="$3"
        h={82}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pressable
          h={70}
          w={90}
          bg="$warning500"
          borderRadius="$md"
          p="$2"
          onPress={() => {}}
        >
          <VStack flex={1} alignItems="center" justifyContent="center">
            <Icon size="xl" as={HeartHandshakeIcon} color="$white" />
            <Text size="xs" color="$white">
              {'Offer Help'}
            </Text>
          </VStack>
        </Pressable>
        <Pressable
          h={70}
          w={90}
          bg="$secondary500"
          borderRadius="$md"
          p="$2"
          onPress={onSkip}
        >
          <VStack flex={1} alignItems="center" justifyContent="center">
            <Icon size="xl" as={RedoIcon} color="$white" />
            <Text size="xs" color="$white">
              {'Skip'}
            </Text>
          </VStack>
        </Pressable>
        <Pressable
          h={70}
          w={90}
          bg="$success500"
          borderRadius="$md"
          p="$2"
          onPress={() => {}}
        >
          <VStack flex={1} alignItems="center" justifyContent="center">
            <Icon size="xl" as={HelpingHandIcon} color="$white" />
            <Text size="xs" color="$white">
              {'Ask for Help'}
            </Text>
          </VStack>
        </Pressable>
      </Box>
    </Card>
  );
};
