import { View, StyleSheet, Dimensions } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { SwipeCard } from './SwipeCard';

const { width } = Dimensions.get('window');

const ROTATION = 60;

export const Deck = ({ items, setItems, go }) => {
  const positionX = useSharedValue(0);
  const positionY = useSharedValue(0);

  const animateCard = () => {
    positionX.value = withSpring(
      -width - 200,
      {
        damping: 15,
        stiffness: 80,
        mass: 1,
        overshootClamping: false,
        restDisplacementThreshold: 1,
        restSpeedThreshold: 1,
      },
      () => {
        setItems(items.slice(1)); // Remove the first item from our items array
        positionX.value = 0;
        positionY.value = 0;
      }
    );
  };

  const rStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: positionX.value },
        { translateY: positionY.value },
        { rotateZ: (positionX.value / width) * ROTATION + 'deg' },
      ],
    };
  });

  return (
    <View style={styles.container}>
      {items
        .map((item, index) => {
          const isCurrentIndex = index === 0;
          const cardStyle = isCurrentIndex
            ? [styles.card, rStyle]
            : styles.card;

          return (
            <Animated.View key={item.id} style={cardStyle}>
              <SwipeCard item={item} onSkip={animateCard} go={go} />
            </Animated.View>
          );
        })
        .reverse()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});
