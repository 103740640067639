import { useCallback } from 'react';
import { Pressable } from '@gluestack-ui/themed';
import useUser from './hooks/useUser';
import { UiButton, UiAvatar, UiInput } from './components/ui';
import HeaderContainer from './components/layout/HeaderContainer';
import { BellIcon, SearchIcon } from 'lucide-react-native';
import { useGo } from './hooks/useNav';

export default function TabsHeader({ navigation }) {
  const { data: user } = useUser();
  const go = useGo();

  const handleDrawer = useCallback(() => {
    navigation.openDrawer();
  }, [navigation]);

  return (
    <HeaderContainer>
      <Pressable px="$3" onPress={handleDrawer}>
        <UiAvatar size="sm" profile={user?.data} />
      </Pressable>
      <UiInput flex={1} size="sm" icon={SearchIcon} placeholder="Search..." />
      <UiButton
        size="lg"
        onPress={() => go({ to: '/notifications' })}
        action="secondary"
        variant="outline"
        borderWidth="$0"
        icon={BellIcon}
      />
    </HeaderContainer>
  );
}
