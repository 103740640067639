import { View } from 'react-native';
import { styled } from '@gluestack-style/react';

export default styled(View, {
  width: '$full',
  height: '$16',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  borderBottomWidth: '$1',
  _dark: {
    backgroundColor: '$black',
    borderBottomColor: '$dark200',
  },
  _light: {
    backgroundColor: '$white',
    borderBottomColor: '$light200',
  },
});
