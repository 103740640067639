import React, { useState, useCallback } from 'react';
import { ScrollView } from 'react-native';
import { Image, Text, Box, Heading, Divider } from '@gluestack-ui/themed';
import {
  UserIcon,
  MailIcon,
  LockIcon,
  EyeIcon,
  EyeOffIcon,
} from 'lucide-react-native';
import { UiButton, UiInput, UiLink } from '../../components/ui';
import { useLogin, useUpdatePassword, useNotification } from '@refinedev/core';

import { useGo } from '../../hooks/useNav';

const Password = (props) => {
  const [passwordShow, setPasswordShow] = useState(true);
  const [pass, setPass] = useState('');
  const { mutate: updatePassword } = useUpdatePassword();
  const { open, close } = useNotification();
  const go = useGo();

  const handndleShowPassword = useCallback(() => {
    setPasswordShow((state) => !state);
  }, []);

  const handeleError = useCallback(
    (error) => {
      open?.({
        key: 'register-error',
        type: 'error',
        message: 'Error',
        description: error.message,
      });
    },
    [open]
  );

  const updateUserPassword = useCallback(async () => {
    close?.('register-error');
    updatePassword(
      { password: pass },
      {
        onSuccess: (data) => {
          if (!data.success) return handeleError(new Error(data.error));

          open?.({
            type: 'success',
            message: 'Success',
            description: 'This is a success message',
          });
          go({ to: '/feed' });
        },
        onError: handeleError,
      }
    );
  }, [pass, updatePassword, go, open, close, handeleError]);

  return (
    <Box px="$8" flex={1}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
      >
        <Image
          h="$10"
          mb="$5"
          // eslint-disable-next-line no-undef
          source={require('../../../assets/bclogo.png')}
          resizeMode="contain"
        />
        <Heading size="2xl" textAlign="center" mb="$5">
          Update Password
        </Heading>
        <UiInput
          autoCapitalize={'none'}
          autoComplete={'new-password'}
          autoCorrect={false}
          mb="$5"
          borderRadius="$xl"
          size="xl"
          icon={LockIcon}
          iconRight={passwordShow ? EyeIcon : EyeOffIcon}
          placeholder="Password"
          type="password"
          onChangeText={(value) => setPass(value)}
          value={pass}
        />
        <UiButton
          mb="$5"
          action="negative"
          onPress={updateUserPassword}
          title="UPDATE PASSWORD"
        />
        <Divider w="$full" mb="$5" />
        <Box display="flex" w="$full" alignItems="center">
          <UiLink to="/login">Sign in</UiLink>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default Password;
