import { useWindowDimensions } from 'react-native';
import { useIsAuthenticated } from '@refinedev/core';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import SignIn from './pages/Auth/SignIn';
import SignUp from './pages/Auth/SignUp';
import Forgot from './pages/Auth/Forgot';
import Password from './pages/Auth/Password';
import Home from './pages/Home';
import Post from './pages/Post';
import Profile from './pages/Profile';
import ProfileEdit from './pages/ProfileEdit';
import Match from './pages/Match';
import Notifications from './pages/Notifications';
import Pictures from './pages/Pictures';
import Loading from './pages/Loading';
import Network from './pages/Network';
import Chats from './pages/Chats';
import ChatActive from './pages/ChatActive';
import ChatNew from './pages/ChatNew';
import DefaultHeader from './DefaultHeader';
import TabsHeader from './TabsHeader';
import Sidemenu from './Sidemenu';
import Tabmenu from './Tabmenu';

const linking = {
  prefixes: [
    'https://app.breakthroughconnect.com',
    'http://localhost:19006',
    'https://breakthroughconnect-app-77it.vercel.app',
  ],
  config: {
    screens: {
      Home: {
        screens: {
          Tabs: {
            screens: {
              Feed: '/feed',
              Match: '/match',
              Network: '/network',
              Message: '/chats',
            },
          },
        },
      },
      Settings: '/settings',
      Profile: '/profile/:id',
      ProfileEdit: '/edit-profile',
      Post: '/post',
      Notifications: '/notifications',
      Pictures: '/picture',
      SignIn: '/login',
      SignUp: '/register',
      Forgot: '/forgot-password',
      Password: '/update-password',
      Chat: '/chats/:id',
      Connect: '/connect/:partnerId',
    },
  },
};

const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

function TabsNavigation() {
  return (
    <Tab.Navigator tabBar={Tabmenu} screenOptions={{ headerShown: false }}>
      <Tab.Screen name="Feed" component={Home} />
      <Tab.Screen
        name="Match"
        component={Match}
        options={{ gestureEnabled: false }}
      />
      <Tab.Screen name="Network" component={Network} />
      <Tab.Screen name="Message" component={Chats} />
    </Tab.Navigator>
  );
}

function DrawerNavigation() {
  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator
      drawerContent={Sidemenu}
      screenOptions={{
        drawerType: dimensions.width >= 768 ? 'permanent' : 'front',
        header: TabsHeader,
      }}
    >
      <Drawer.Screen name="Tabs" component={TabsNavigation} />
    </Drawer.Navigator>
  );
}

export default function Navigation() {
  const { data, isLoading } = useIsAuthenticated();
  console.log('Navigation', data);
  if (isLoading) return <Loading />;
  return (
    <NavigationContainer
      linking={linking}
      fallback={<Loading />}
      theme={{ colors: { background: 'transparent' } }}
    >
      <Stack.Navigator>
        {data?.authenticated && (
          <>
            <Stack.Screen
              name="Home"
              component={DrawerNavigation}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Group
              screenOptions={{
                header: DefaultHeader,
                animation: 'slide_from_bottom',
              }}
            >
              <Stack.Screen name="Settings" component={Notifications} />
              <Stack.Screen name="Profile" component={Profile} />
              <Stack.Screen
                name="ProfileEdit"
                component={ProfileEdit}
                options={{
                  title: 'Profile',
                }}
              />
              <Stack.Screen name="Post" component={Post} />
              <Stack.Screen name="Notifications" component={Notifications} />
              <Stack.Screen name="Chat" component={ChatActive} />
              <Stack.Screen name="Connect" component={ChatNew} />
            </Stack.Group>

            <Stack.Group
              screenOptions={{
                header: DefaultHeader,
                animation: 'fade',
                presentation: 'containedModal',
              }}
            >
              <Stack.Screen name="Pictures" component={Pictures} />
            </Stack.Group>
          </>
        )}

        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen name="SignIn" component={SignIn} />
          <Stack.Screen name="SignUp" component={SignUp} />
          <Stack.Screen name="Forgot" component={Forgot} />
          <Stack.Screen name="Password" component={Password} />
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
