import React from 'react';
import { Spinner, Center } from '@gluestack-ui/themed';

export default function Loading() {
  return (
    <Center flex={1}>
      <Spinner size="large" />
    </Center>
  );
}
