import React from 'react';
import { useWindowDimensions } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';
import { PinchGestureHandler } from 'react-native-gesture-handler';

const PinchableBox = ({ children, style }) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const scale = useSharedValue(1);
  const focalX = useSharedValue(0);
  const focalY = useSharedValue(0);

  const pinchGestureHandler = useAnimatedGestureHandler({
    onActive: (event) => {
      if (event.scale >= 1) {
        scale.value = event.scale;
      }
      focalX.value = event.focalX;
      focalY.value = event.focalY;
    },
    onEnd: () => {
      scale.value = withSpring(1);
    },
  });

  const rStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: focalX.value },
        { translateY: focalY.value },
        { translateX: -windowWidth / 2 },
        { translateY: -windowHeight / 2 },
        { scale: scale.value },
        { translateX: -focalX.value },
        { translateY: -focalY.value },
        { translateX: windowWidth / 2 },
        { translateY: windowHeight / 2 },
      ],
    };
  });

  return (
    <PinchGestureHandler onGestureEvent={pinchGestureHandler}>
      <Animated.View style={[style, rStyle]}>
        {children}
      </Animated.View>
    </PinchGestureHandler>
  );
};

export default PinchableBox;
