import React, { useEffect, useState, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import {
  useCreate,
  useOne,
  useGetIdentity,
  useInfiniteList,
} from '@refinedev/core';
import { useGo } from '../hooks/useNav';
import {
  Box,
  Image,
  VStack,
  HStack,
  Icon,
  Heading,
  Text,
  Center,
  Pressable,
} from '@gluestack-ui/themed';
import Card from '../components/layout/Card';
import Background from '../components/layout/Background';
import { UiButton, UiAvatar } from '../components/ui';
import {
  HelpingHandIcon,
  HeartHandshakeIcon,
  SendIcon,
  UserIcon,
} from 'lucide-react-native';
import Screen from '../Screen';

export default function Profile({
  navigation,
  route: {
    params: { id },
  },
}) {
  const go = useGo();

  const { data: profiles } = useOne({
    id: id,
    resource: 'profiles',
    queryOptions: {
      enabled: !!id,
    },
    meta: {
      select: '*, chats(id)',
    },
  });

  useEffect(() => {
    if (profiles?.data?.displayName)
      navigation.setOptions({
        title: profiles?.data?.displayName,
      });
  }, [profiles?.data?.displayName]);

  const handleChat = useCallback(
    (type) => {
      if (profiles?.data?.chats?.length > 0)
        go({ to: `/chats/${profiles?.data?.chats[0]?.id}` });
      else go({ to: `/connect/${profiles?.data?.id}?type=${type}` });
    },
    [profiles?.data?.chats?.length]
  );

  return (
    <Screen hasBack>
      <ScrollView>
        <Background p="$4" pt="$32">
          <Card flex={1} position="relative">
            <Center sx={{ posiotion: 'absolute', zIndex: 100, top: -112 }}>
              <UiAvatar w="$56" h="$56" profile={profiles?.data} />
            </Center>
            <VStack
              w="$full"
              flex={1}
              sx={{ posiotion: 'absolute', zIndex: 100, top: -96 }}
            >
              <Center mb="$3">
                <Heading size="xl">{profiles?.data?.displayName}</Heading>
              </Center>
              <Box
                w="$full"
                mb="$3"
                px="$3"
                h={82}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Pressable
                  h={70}
                  w={90}
                  bg="$warning500"
                  borderRadius="$md"
                  p="$2"
                  onPress={() => handleChat('offerHelp')}
                >
                  <VStack flex={1} alignItems="center" justifyContent="center">
                    <Icon size="xl" as={HeartHandshakeIcon} color="$white" />
                    <Text size="xs" color="$white">
                      {'Offer Help'}
                    </Text>
                  </VStack>
                </Pressable>
                <Pressable
                  h={70}
                  w={90}
                  bg="$primary500"
                  borderRadius="$md"
                  p="$2"
                  onPress={() => handleChat('default')}
                >
                  <VStack flex={1} alignItems="center" justifyContent="center">
                    <Icon size="xl" as={SendIcon} color="$white" />
                    <Text size="xs" color="$white">
                      {'Message'}
                    </Text>
                  </VStack>
                </Pressable>
                <Pressable
                  h={70}
                  w={90}
                  bg="$success500"
                  borderRadius="$md"
                  p="$2"
                  onPress={() => handleChat('needHelp')}
                >
                  <VStack flex={1} alignItems="center" justifyContent="center">
                    <Icon size="xl" as={HelpingHandIcon} color="$white" />
                    <Text size="xs" color="$white">
                      {'Ask for Help'}
                    </Text>
                  </VStack>
                </Pressable>
              </Box>
              <Box px="$3">
                <VStack mb="$5">
                  <HStack alignItems="center" mb="$3">
                    <Icon
                      as={HelpingHandIcon}
                      size="lg"
                      mr="$3"
                      color={'$success500'}
                    />
                    <Heading size="sm">{'Can Help With'}</Heading>
                  </HStack>
                  <Text>{profiles?.data?.canHelp}</Text>
                </VStack>
                <VStack mb="$5">
                  <HStack alignItems="center" mb="$3">
                    <Icon
                      as={HeartHandshakeIcon}
                      size="lg"
                      mr="$3"
                      color={'$warning500'}
                    />
                    <Heading size="sm">{'Need Help With'}</Heading>
                  </HStack>
                  <Text>{profiles?.data?.needHelp}</Text>
                </VStack>
                <VStack>
                  <HStack alignItems="center" mb="$3">
                    <Icon as={UserIcon} size="lg" mr="$3" />
                    <Heading size="sm">{'Bio'}</Heading>
                  </HStack>
                  <Text>{profiles?.data?.bio}</Text>
                </VStack>
              </Box>
            </VStack>
          </Card>
        </Background>
      </ScrollView>
    </Screen>
  );
}
