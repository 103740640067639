import uuid from 'uuid';
import { supabaseClient } from './supabaseClient';
import { decode } from 'base64-arraybuffer';

const mimeExtensions = {
  'image/gif': 'gif',
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/tiff': 'tif',
  'image/webp': 'webp',
};

async function uploadImageAsync(asset, folder) {
  const [, contentType] = asset.uri.match(
    /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
  );
  const path = `${folder ? `${folder}/` : ''}${uuid.v4()}.${
    mimeExtensions[contentType]
  }`;

  const { error } = await supabaseClient.storage
    .from('pictures')
    .upload(path, decode(asset.base64), {
      contentType,
    });

  if (error) throw new Error(error.message);

  const { data } = await supabaseClient.storage
    .from('pictures')
    .getPublicUrl(path);

  return data.publicUrl;
}

export default uploadImageAsync;
