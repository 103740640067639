import { Box, Text } from '@gluestack-ui/themed';
import { UiButton } from './components/ui';
import HeaderContainer from './components/layout/HeaderContainer';
import { XIcon } from 'lucide-react-native';

export default function DefaultHeader({ route, options }) {
  return (
    <HeaderContainer>
      {options?.headerLeft?.() || (
        <UiButton
          mx="$3"
          size="xl"
          action="secondary"
          variant="outline"
          borderWidth="$0"
          icon={XIcon}
        />
      )}
      <Box flex={1}>
        <Text size="xl">{options.title || route.name}</Text>
      </Box>
      {options?.headerRight?.() || null}
    </HeaderContainer>
  );
}
