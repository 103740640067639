import { View } from 'react-native';
import { styled } from '@gluestack-style/react';

export default styled(View, {
  borderRadius: '$lg',
  _dark: {
    backgroundColor: '$black',
    borderBottomColor: '$dark200',
  },
  _light: {
    backgroundColor: '$white',
    borderBottomColor: '$light200',
  },
});
