import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated';

const GrowAndSlide = ({ renderInitialContent, renderTargetContent, initialHeight, targetHeight }) => {
  const [contentSwitch, setContentSwitch] = useState(false);
  const translateY = useSharedValue(0);
  const containerHeight = useSharedValue(initialHeight);

  const oldContentAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  });

  const newContentAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value - (initialHeight + targetHeight) }],
    };
  });

  const containerAnimatedStyle = useAnimatedStyle(() => {
    return {
      height: containerHeight.value,
    };
  });

  const animateContent = () => {
    translateY.value = withTiming(
      contentSwitch ? 0 : (initialHeight + targetHeight),
      {
        duration: 500,
        easing: Easing.inOut(Easing.ease),
      },
    );

    containerHeight.value = withTiming(
      contentSwitch ? initialHeight : targetHeight,
      {
        duration: 500,
        easing: Easing.inOut(Easing.ease),
      },
      () => {
        setContentSwitch(!contentSwitch);
      }
    );
  };

  return (
    <Animated.View style={[styles.animationContainer, containerAnimatedStyle]}>
      <Animated.View style={[styles.content, oldContentAnimatedStyle]}>
        {renderInitialContent({ animateContent, inFocus: !contentSwitch })}
      </Animated.View>
      <Animated.View style={[styles.content, newContentAnimatedStyle]}>
        {renderTargetContent({ animateContent, inFocus: contentSwitch })}
      </Animated.View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  animationContainer: {
    width: '100%',
    overflow: 'hidden',
  },
  content: {
    position: 'absolute',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
});

export default GrowAndSlide;
