import { GluestackUIProvider } from '@gluestack-ui/themed';
import useAsyncStorage from './hooks/useAsyncStorage';
import { config } from '../gluestack-ui.config';

export default function ThemeProvider({ children }) {
  const [colorMode] = useAsyncStorage('colorMode');

  return (
    <GluestackUIProvider config={config.theme} colorMode={colorMode}>
      {children}
    </GluestackUIProvider>
  );
}
