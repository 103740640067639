/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { Platform } from 'react-native';

import { useNavigation, useRoute, useLinkTo } from '@react-navigation/native';

export const useGo = () => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const { path: pathname, params = {} } = useRoute();

  return useCallback(
    ({
      to,
      type = 'push',
      query,
      hash,
      options: { keepQuery, keepHash } = {},
    }) => {
      if (Platform.OS === 'web') {
        if (to && pathname !== to && to !== 'undefined') {
          const path = to.startsWith('/') ? to : `/${to}`;
          linkTo(path);
        }
      } else {
        // if (type === 'push')
        //   navigation.navigate(screenName, screenObject);
        // else if (type === 'replace')
        //   navigation.replace(screenName, screenObject);
      }
    },
    [linkTo, pathname, navigation, params]
  );
};

export const useBack = () => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  return useCallback(() => {
    if (Platform.OS === 'web') linkTo('/feed');
    else navigation.goBack();
  }, [navigation, linkTo]);
};

// export function routerProvider(navigationRef: any, linking: any) {
//   const convertToNumberIfPossible = (value: string | undefined) => {
//     if (typeof value === 'undefined') {
//       return value;
//     }
//     const num = Number(value);
//     if (`${num}` === value) {
//       return num;
//     }
//     return value;
//   };

//   // recursive function to search for a value in an object and return key value pair and all parents
//   const objectDeepSearch = (obj: any, value: any, path: string[] = []) => {
//     let found: any = false;
//     Object.entries(obj).forEach(([key, val]) => {
//       if (val === value) {
//         found = { key, val, path };
//       } else if (typeof val === 'object') {
//         const result = objectDeepSearch(
//           val,
//           value,
//           key === 'screens' ? path : [...path, key]
//         );
//         if (result) {
//           found = result;
//         }
//       }
//     });
//     return found;
//   };

//   // check is string matches path template string
//   const isMatch = (path: string, template: string) => {
//     const pathParts = path.split('/');
//     const templateParts = template.split('/');
//     if (pathParts.length !== templateParts.length) {
//       return false;
//     }
//     let isMatch = true;
//     pathParts.forEach((part, i) => {
//       if (
//         part !== templateParts[i] &&
//         templateParts[i].startsWith(':') === false
//       ) {
//         isMatch = false;
//       }
//     });
//     return isMatch;
//   };

//   const findScreenName = (path: string) => {
//     const found = objectDeepSearch(linking?.config, path);
//     if (!found) throw new Error(`No screen found for path: ${path}`);
//     return found;
//   };

//   function tryToGo(go: any) {
//     setTimeout(() => {
//       if (navigationRef.isReady()) {
//         go();
//       } else {
//         tryToGo(go);
//       }
//     }, 10000);
//   }

//   const routerBindings: RouterBindings = {
//     go: () => {
//       const linkTo = useLinkTo();
//       const navigation = useNavigation();
//       const { path: pathname, params = {} } = useRoute();
//       const ready = navigationRef.isReady();

//       const fn = useCallback(
//         ({
//           to,
//           type = 'push',
//           query,
//           hash,
//           options: { keepQuery, keepHash } = {},
//         }: GoConfig) => {
//           // if not ready, try to re-run function again in 10 seconds
//           if (!ready)
//             setTimeout(() => {
//               fn({ to, type, query, hash, options: { keepQuery, keepHash } });
//             }, 1000);
//           else {
//             if (Platform.OS === 'web') {
//               if (to && pathname !== to && to !== 'undefined') {
//                 const path = to.startsWith('/') ? to : `/${to}`;
//                 linkTo(path);
//               }
//             } else {
//               const screen = findScreenName(to);
//               const screenName = screen.path.length
//                 ? screen.path[0]
//                 : screen.key;
//               const screenObject = screen.path.length
//                 ? {
//                     screen: screen.path[1],
//                     params: {
//                       screen: screen.key,
//                       params: {
//                         ...params,
//                         ...query,
//                         hash,
//                       },
//                     },
//                   }
//                 : {
//                     ...params,
//                     ...query,
//                     hash,
//                   };
//               console.log({ screenName, screenObject });
//               if (type === 'push')
//                 navigation.navigate(screenName, screenObject);
//               else if (type === 'replace')
//                 navigation.replace(screenName, screenObject);
//             }
//           }
//         },
//         [linkTo, pathname, navigation, params, ready]
//       );

//       return fn;
//     },
//     back: () => {
//       const navigation = useNavigation();
//       const linkTo = useLinkTo();

//       const fn = useCallback(() => {
//         console.log('back');
//         if (navigationRef.isReady()) {
//           if (Platform.OS === 'web') linkTo('/feed');
//           else navigation.goBack();
//         }
//       }, [navigation, linkTo]);

//       return fn;
//     },
//     parse: () => {
//       const { path: pathname, params = {} } = useRoute();
//       const { resources } = useContext(ResourceContext);

//       const { resource, action } = React.useMemo(() => {
//         return matchResourceFromRoute(pathname || '', resources);
//       }, [resources, pathname]);

//       const fn = useCallback(() => {
//         //console.log({ pathname, params });
//         if (!pathname) return {};
//         const response: ParseResponse = {
//           ...(resource && { resource }),
//           ...(action && { action }),
//           pathname,
//           params: {
//             ...params,
//             current: convertToNumberIfPossible(params?.current as string) as
//               | number
//               | undefined,
//             pageSize: convertToNumberIfPossible(params?.pageSize as string) as
//               | number
//               | undefined,
//             to: params?.to
//               ? decodeURIComponent(params?.to as string)
//               : undefined,
//           },
//         };
//         //console.log({ response });
//         return response;
//       }, [pathname, params, resource, action]);

//       return fn;
//     },
//     Link: ({ ...props }) => <Link {...props} />,
//   };

//   return routerBindings;
// }
