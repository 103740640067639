import React, { useMemo, useState, useCallback } from 'react';
import { View, FlatList } from 'react-native';
import {
  useCreate,
  useOne,
  useGetIdentity,
  useInfiniteList,
} from '@refinedev/core';
import { useGo } from '../hooks/useNav';
import { getAllPagesData } from '../utils/data';
import { ChatListItem } from '../components/layout/ChatListItem';
import { ChatBubble } from '../components/layout/ChatBubble';
import { ChatInput } from '../components/layout/ChatInput';
import Background from '../components/layout/Background';
import Card from '../components/layout/Card';
import { UiButton } from '../components/ui';
import { useFocusEffect } from '@react-navigation/native';
import { HStack, Box, Text, Divider } from '@gluestack-ui/themed';

import { SendIcon } from 'lucide-react-native';
import useIcebreaker from '../hooks/useIcebreaker';
import Screen from '../Screen';

//TODO: PAGINATION!

export default function ChatNew({ navigation, route: { params } }) {
  const { data: identity } = useGetIdentity();
  const [value, setValue] = useState();
  const go = useGo();
  const { mutate: create } = useCreate();
  const { type, partnerId } = params;
  const icebreaker = useIcebreaker();

  const { data: profiles } = useOne({
    id: partnerId,
    resource: 'profiles',
    queryOptions: {
      enabled: !!partnerId,
    },
    meta: {
      select: 'id, avatarUrl, displayName, needHelp, canHelp',
    },
  });

  const opener = useMemo(() => {
    if (type === 'needHelp')
      return `Hi, I saw that you need help${
        profiles?.data?.needHelp ? ` with ${profiles?.data?.needHelp}` : ''
      }. I can help you${profiles?.data?.needHelp ? ` with that` : ''}!`;
    else if (type === 'canHelp')
      return `Hi, I saw that you can help${
        profiles?.data?.canHelp ? ` with ${profiles?.data?.canHelp}` : ''
      }. I need help${profiles?.data?.needHelp ? ` with that` : ''}!`;
    else return icebreaker;
  }, [icebreaker, type, profiles?.data?.canHelp, profiles?.data?.needHelp]);

  const handleSend = useCallback(
    (sendOpener) => {
      create(
        {
          resource: 'chats',
          values: {
            members: [identity.id, partnerId],
          },
          meta: {
            select: 'id',
          },
        },
        {
          onSuccess: ({ data }) => {
            create(
              {
                resource: 'messages',
                values: {
                  message: sendOpener ? opener : value,
                  chatId: data.id,
                },
              },
              {
                onSuccess: () => {
                  go({ to: `/chats/${data.id}` });
                },
              }
            );
          },
        }
      );
    },
    [identity?.id, partnerId, value, opener, create, go]
  );

  return (
    <Screen hasBack title={profiles?.data?.displayName}>
      <Background flex={1}>
        <Box flex={1} mx="$5" justifyContent="center" alignItems="center">
          <Text
            textAlign="center"
            mb="$5"
          >{`We prepared an icebreaker that you could use to connect with ${profiles?.data?.displayName}`}</Text>
          <Box bg="$primary500" borderRadius="$md" py="$3" px="$5">
            <Text color="$white">{opener}</Text>
            <Divider my="$5" bg="$white" />
            <UiButton
              icon={SendIcon}
              title="Send Icebreaker"
              onPress={() => handleSend(true)}
            />
          </Box>
          <Divider my="$12" />
          <Text textAlign="center">{'or write your own message below'}</Text>
        </Box>

        <ChatInput
          value={value}
          setValue={setValue}
          handleSend={() => handleSend(false)}
        />
      </Background>
    </Screen>
  );
}
