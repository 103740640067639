import 'react-native-url-polyfill/auto';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createClient } from '@refinedev/supabase';
import { Platform } from 'react-native';

const ExpoSecureStoreAdapter = {
  getItem: (key) => {
    return SecureStore.getItemAsync(key);
  },
  setItem: (key, value) => {
    SecureStore.setItemAsync(key, value);
  },
  removeItem: (key) => {
    SecureStore.deleteItemAsync(key);
  },
};

const AsyncStorageAdapter = {
  getItem: (key) => {
    return AsyncStorage.getItem(key);
  },
  setItem: (key, value) => {
    AsyncStorage.setItem(key, value);
  },
  removeItem: (key) => {
    AsyncStorage.removeItem(key);
  },
};

const SUPABASE_URL = 'https://lxsqbjufbyprxynuvdmo.supabase.co';
const SUPABASE_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx4c3FianVmYnlwcnh5bnV2ZG1vIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODc4ODUxODksImV4cCI6MjAwMzQ2MTE4OX0.FxiIn2N2In7bkBbwsLNqfk86HVAvSwcWlMiRbzW2ZNM';

export const supabaseClient = createClient(SUPABASE_URL, SUPABASE_KEY, {
  db: {
    schema: 'public',
  },
  auth: {
    storage:
      Platform.OS === 'web' ? AsyncStorageAdapter : ExpoSecureStoreAdapter,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: Platform.OS === 'web',
  },
});
