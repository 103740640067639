import { useOne, useGetIdentity } from '@refinedev/core';

export default function useUser(options) {
  const { data: identity } = useGetIdentity();

  const profile = useOne({
    resource: 'profiles',
    id: identity?.id,
    enabled: !!identity?.id,
    ...options
  });

  return profile;
}
