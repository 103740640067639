import { Box, VStack, HStack, Icon, Heading, Text } from '@gluestack-ui/themed';
import Background from './Background';
import { BellIcon } from 'lucide-react-native';

export const NotificationItem = ({ title, body, type }) => {
  return (
    <Background borderBottomWidth="$1" py="$2">
      <HStack alignItems="center">
        <Icon px="$5" as={BellIcon} />
        <VStack flexGrow={1}>
          {title && <Heading size="sm">title</Heading>}
          {body && <Text>body</Text>}
        </VStack>
      </HStack>
    </Background>
  );
};
