import { View } from 'react-native';
import { styled } from '@gluestack-style/react';

export default styled(View, {
  variants: {
    backgroundShade: {
      50: {
        _dark: {
          backgroundColor: '$dark50',
        },
        _light: {
          backgroundColor: '$light50',
        },
      },
      100: {
        _dark: {
          backgroundColor: '$dark100',
        },
        _light: {
          backgroundColor: '$light100',
        },
      },
      200: {
        _dark: {
          backgroundColor: '$dark200',
        },
        _light: {
          backgroundColor: '$light200',
        },
      },
      300: {
        _dark: {
          backgroundColor: '$dark300',
        },
        _light: {
          backgroundColor: '$light300',
        },
      },
      400: {
        _dark: {
          backgroundColor: '$dark400',
        },
        _light: {
          backgroundColor: '$light400',
        },
      },
      500: {
        _dark: {
          backgroundColor: '$dark500',
        },
        _light: {
          backgroundColor: '$light500',
        },
      },
      600: {
        _dark: {
          backgroundColor: '$dark600',
        },
        _light: {
          backgroundColor: '$light600',
        },
      },
      700: {
        _dark: {
          backgroundColor: '$dark700',
        },
        _light: {
          backgroundColor: '$light700',
        },
      },
      800: {
        _dark: {
          backgroundColor: '$dark800',
        },
        _light: {
          backgroundColor: '$light800',
        },
      },
      900: {
        _dark: {
          backgroundColor: '$dark900',
        },
        _light: {
          backgroundColor: '$light900',
        },
      },
    },
    borderShade: {
      50: {
        _dark: {
          borderColor: '$dark50',
        },
        _light: {
          borderColor: '$light50',
        },
      },
      100: {
        _dark: {
          borderColor: '$dark100',
        },
        _light: {
          borderColor: '$light100',
        },
      },
      200: {
        _dark: {
          borderColor: '$dark200',
        },
        _light: {
          borderColor: '$light200',
        },
      },
      300: {
        _dark: {
          borderColor: '$dark300',
        },
        _light: {
          borderColor: '$light300',
        },
      },
      400: {
        _dark: {
          borderColor: '$dark400',
        },
        _light: {
          borderColor: '$light400',
        },
      },
      500: {
        _dark: {
          borderColor: '$dark500',
        },
        _light: {
          borderColor: '$light500',
        },
      },
      600: {
        _dark: {
          borderColor: '$dark600',
        },
        _light: {
          borderColor: '$light600',
        },
      },
      700: {
        _dark: {
          borderColor: '$dark700',
        },
        _light: {
          borderColor: '$light700',
        },
      },
      800: {
        _dark: {
          borderColor: '$dark800',
        },
        _light: {
          borderColor: '$light800',
        },
      },
      900: {
        _dark: {
          borderColor: '$dark900',
        },
        _light: {
          borderColor: '$light900',
        },
      },
    },
  },
  defaultProps: {
    backgroundShade: '100',
    borderShade: '400',
  },
});
