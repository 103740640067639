import 'setimmediate';
import 'react-native-gesture-handler';
import Main from './src/Main';
import ThemeProvider from './src/ThemeProvider';
import AsyncStorageProvider from './src/AsyncStorageProvider';
import { useColorScheme } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

export default function App() {
  const colorScheme = useColorScheme();
  return (
    <AsyncStorageProvider
      initialState={{
        colorMode: colorScheme,
      }}
    >
      <ThemeProvider>
        <SafeAreaProvider>
          <Main />
        </SafeAreaProvider>
      </ThemeProvider>
    </AsyncStorageProvider>
  );
}
