import React, { useEffect, useState, useCallback } from 'react';
import { View, FlatList } from 'react-native';
import {
  useCreate,
  useOne,
  useGetIdentity,
  useInfiniteList,
} from '@refinedev/core';
import { getAllPagesData } from '../utils/data';
import { ChatListItem } from '../components/layout/ChatListItem';
import { ChatBubble } from '../components/layout/ChatBubble';
import { ChatInput } from '../components/layout/ChatInput';
import Background from '../components/layout/Background';
import Screen from '../Screen';

//TODO: PAGINATION!

export default function ChatActive({ navigation, route: { params } }) {
  const { data: identity } = useGetIdentity();
  const [value, setValue] = useState();
  const { mutate: create } = useCreate();
  const { id } = params;

  const { data: chat } = useOne({
    id: id,
    resource: 'chats',
    queryOptions: {
      enabled: !!id,
    },
    meta: {
      select: 'id, profiles(id, avatarUrl, displayName)',
    },
  });

  const { data: messages } = useInfiniteList({
    resource: 'messages',
    liveMode: 'auto',
    queryOptions: {
      enabled: !!id,
    },
    pagination: {
      pageSize: 20,
    },
    meta: {
      select: 'id, message, userId, createdAt',
    },
    filters: [
      {
        field: 'chatId',
        operator: 'eq',
        value: id,
      },
    ],
  });

  const handleSend = useCallback(() => {
    create({
      resource: 'messages',
      values: {
        message: value,
        chatId: id,
      },
      successNotification: false,
    });
  }, [id, create, value]);

  return (
    <Screen
      hasBack
      title={
        chat?.data?.profiles?.find(({ id }) => id !== identity?.id)?.displayName
      }
    >
      <Background flex={1}>
        <FlatList
          display={id ? 'flex' : 'none'}
          flex={1}
          data={getAllPagesData(messages)}
          renderItem={({ item }) => (
            <ChatBubble key={item?.id} item={item} myId={identity?.id} />
          )}
        />
        <ChatInput value={value} setValue={setValue} handleSend={handleSend} />
      </Background>
    </Screen>
  );
}
