import React from 'react';
import Card from './Card';
import { HStack } from '@gluestack-ui/themed';
import { UiButton, UiInput } from '../ui';
import { SendIcon } from 'lucide-react-native';

export function ChatInput({ value, setValue, handleSend }) {
  return (
    <Card w="$full" h="$18" borderRadius="$none">
      <HStack>
        <UiInput
          flex={1}
          pt="$3"
          borderWidth="$0"
          borderRadius="$none"
          placeholder="What's on your mind?"
          multiline
          value={value}
          onChangeText={setValue}
        />
        <UiButton
          size="md"
          borderRadius="$none"
          action="primary"
          icon={SendIcon}
          onPress={handleSend}
        />
      </HStack>
    </Card>
  );
}
