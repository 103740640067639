import React from 'react';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import Navigation from './Navigation';
import Background from './components/layout/Background';
import { useToast } from '@gluestack-ui/themed';

import { Refine } from '@refinedev/core';
import { notificationProvider } from './lib/notificationProvider';
import useAsyncStorage from './hooks/useAsyncStorage';
import { dataProvider, liveProvider } from '@refinedev/supabase';
import { supabaseClient } from './lib/supabaseClient';
import authProvider from './lib/authProvider';

import { EventProvider } from 'react-native-outside-press';

export default function App() {
  const [colorMode] = useAsyncStorage('colorMode');
  const toast = useToast();
  const insets = useSafeAreaInsets();
  return (
    <Refine
      dataProvider={dataProvider(supabaseClient)}
      liveProvider={liveProvider(supabaseClient)}
      authProvider={authProvider}
      notificationProvider={notificationProvider(toast)}
      options={{
        liveMode: 'manual',
        mutationMode: 'optimistic',
        disableTelemetry: true,
        reactQuery: {
          devtoolConfig: false,
        },
      }}
    >
      <EventProvider style={{ flex: 1 }}>
        <StatusBar style={colorMode === 'dark' ? 'light' : 'dark'} />
        <Background flex={1} pt={insets.top}>
          <Navigation />
        </Background>
      </EventProvider>
    </Refine>
  );
}
