import React from 'react';
import {
  Input,
  InputIcon,
  InputField,
  InputSlot,
  Icon,
} from '@gluestack-ui/themed';
import { pick, omit } from 'lodash';

const textInputProps = [
  'allowFontScaling',
  'autoCapitalize',
  'autoComplete',
  'autoCorrect',
  'autoFocus',
  'blurOnSubmit',
  'caretHidden',
  'clearButtonModeiOS',
  'clearTextOnFocusiOS',
  'contextMenuHidden',
  'dataDetectorTypesiOS',
  'defaultValue',
  'cursorColorAndroid',
  'disableFullscreenUIAndroid',
  'editable',
  'enablesReturnKeyAutomaticallyiOS',
  'enterKeyHint',
  'importantForAutofillAndroid',
  'inlineImageLeftAndroid',
  'inlineImagePaddingAndroid',
  'inputAccessoryViewIDiOS',
  'inputMode',
  'keyboardAppearanceiOS',
  'keyboardType',
  'maxFontSizeMultiplier',
  'maxLength',
  'multiline',
  'numberOfLinesAndroid',
  'onBlur',
  'onChange',
  'onChangeText',
  'onContentSizeChange',
  'onEndEditing',
  'onPressIn',
  'onPressOut',
  'onFocus',
  'onKeyPress',
  'onLayout',
  'onScroll',
  'onSelectionChange',
  'onSubmitEditing',
  'placeholder',
  'placeholderTextColor',
  'readOnly',
  'returnKeyLabelAndroid',
  'returnKeyType',
  'rejectResponderTerminationiOS',
  'rowsAndroid',
  'scrollEnablediOS',
  'secureTextEntry',
  'selection',
  'selectionColor',
  'selectTextOnFocus',
  'showSoftInputOnFocus',
  'spellCheckiOS',
  'textAlign',
  'textContentTypeiOS',
  'passwordRulesiOS',
  'style',
  'textBreakStrategyAndroid',
  'underlineColorAndroidAndroid',
  'value',
  'lineBreakStrategyIOS',
];

export const UiInput = ({ icon, iconRight, type = 'text', ...props }) => {
  return (
    <Input {...omit(props, textInputProps)}>
      {icon && (
        <InputSlot pl="$3">
          <InputIcon as={icon} />
        </InputSlot>
      )}
      <InputField type={type} {...pick(props, textInputProps)} />
      {iconRight && (
        <InputSlot pr="$3">
          <InputIcon as={iconRight} />
        </InputSlot>
      )}
    </Input>
  );
};
