import React, { useState } from 'react';
import { FlatList, View, Text } from 'react-native';
import { useInfiniteList } from '@refinedev/core';
import { getAllPagesData } from '../utils/data';
import { NotificationItem } from '../components/layout/NotificationItem';
import Screen from '../Screen';

export default function Notifications() {
  const {
    data,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteList({
    resource: 'notifications',
    pagination: {
      pageSize: 25,
    },
  });

  return (
    <Screen hasBack>
      <FlatList
        refreshing={isLoading || isFetchingNextPage}
        data={getAllPagesData(data)}
        renderItem={({ item }) => (
          <NotificationItem
            key={item.id}
            title={item.title}
            body={item.body}
            type={item.type}
          />
        )}
      />
    </Screen>
  );
}
