import AsyncStorage from '@react-native-async-storage/async-storage';
import { createContext, useCallback, useEffect, useReducer } from 'react';
import { InteractionManager } from 'react-native';

export const AsyncStorageContext = createContext();

const reducer = (state, { key, value }) => ({ ...state, [key]: value });

export default function AsyncStorageProvider({ initialState, children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setAsyncStorage = useCallback((key, value) => {
    dispatch({ key, value });
    InteractionManager.runAfterInteractions(() => {
      AsyncStorage.setItem(key, value);
    });
  }, []);

  useEffect(() => {
    AsyncStorage.multiGet(Object.keys(initialState)).then((values) => {
      values.forEach(([key, value]) => dispatch({ key, value }));
    });
  }, []);

  return (
    <AsyncStorageContext.Provider value={[state, setAsyncStorage]}>
      {children}
    </AsyncStorageContext.Provider>
  );
}
