import {
  Box,
  Image,
  VStack,
  HStack,
  Icon,
  Heading,
  Text,
  Center,
  Pressable,
} from '@gluestack-ui/themed';
import { UiAvatar, UiButton } from '../ui';
import Card from './Card';
import {
  UserPlusIcon,
  HeartHandshakeIcon,
  RedoIcon,
} from 'lucide-react-native';
import TimeAgo from '@andordavoti/react-native-timeago';

export const ChatBubble = ({ myId, item }) => {
  const overwrite = {
    card: item.userId === myId ? { bg: '$primary500' } : {},
    text: item.userId === myId ? { color: '$white', textAlign: 'right' } : {},
  };
  return (
    <Box
      w="$full"
      display="flex"
      px="$3"
      my="$3"
      alignItems={item.userId === myId ? 'flex-end' : 'flex-start'}
    >
      <Card borderRadius="$lg" px="$3" py="$2" {...overwrite.card}>
        <Text size="xs" mb="$1" {...overwrite.text}>
          <TimeAgo dateTo={new Date(item.createdAt)} />
        </Text>
        <Text size="lg" {...overwrite.text}>
          {item.message}
        </Text>
      </Card>
    </Box>
  );
};
