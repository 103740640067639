import React, { useState } from 'react';
import { View, Text, FlatList } from 'react-native';
import { useInfiniteList, useGetIdentity } from '@refinedev/core';
import { getAllPagesData } from '../utils/data';
import { ChatListItem } from '../components/layout/ChatListItem';
import Background from '../components/layout/Background';
import { useGo } from '../hooks/useNav';

//TODO: PAGINATION!

//"Try changing 'profiles' to one of the following: 'profiles!chats_user_id_fkey', 'profiles!chat_users'. Find the desired relationship in the 'details' key."

export default function Chats() {
  const { data: identity } = useGetIdentity();
  const go = useGo();

  const {
    data: chats,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    refetch,
  } = useInfiniteList({
    enabled: !!identity?.id,
    resource: 'chats',
    pagination: {
      pageSize: 20,
    },
    meta: {
      select: 'id, profiles!chat_users(id, avatarUrl, displayName)',
    },
  });

  return (
    <FlatList
      isLoading={isLoading}
      refreshing={isRefetching}
      onRefresh={refetch}
      ItemSeparatorComponent={() => <Background h={1} />}
      data={getAllPagesData(chats)}
      renderItem={({ item }) => (
        <ChatListItem
          key={item.id}
          item={item}
          myId={identity?.id}
          onAction={(id) => go({ to: `/chats/${id}` })}
        />
      )}
    />
  );
}
