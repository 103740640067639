import { useMemo } from 'react';
import icebreakers from '../data/icebreakers';

const useIcebreaker = () => {
  const icebreaker = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * icebreakers.length);
    return icebreakers[randomIndex];
  }, []);
  return icebreaker;
};

export default useIcebreaker;
