import React from 'react';
import {
  Button,
  ButtonIcon,
  ButtonText,
  ButtonSpinner,
} from '@gluestack-ui/themed';

export const UiButton = ({ title, icon, isLoading, ...props }) => {
  return (
    <Button {...props} {...(isLoading ? { isDisabled: true } : {})}>
      {isLoading && <ButtonSpinner mr={title ? '$2' : '$0'} />}
      {!isLoading && icon && <ButtonIcon mr={title ? '$2' : '$0'} as={icon} />}
      {title && <ButtonText>{title}</ButtonText>}
    </Button>
  );
};
