import { AsyncStorageContext } from '../AsyncStorageProvider';
import { useContext, useCallback, useMemo } from 'react';

export default function useAsyncStorage(key) {
  const [storage, setAsyncStorage] = useContext(AsyncStorageContext);

  const setStorage = useCallback((value) => setAsyncStorage(key, value), [key]);

  return useMemo(() => [storage[key], setStorage], [storage[key], setStorage]);
}
