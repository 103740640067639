const icebreakers = [
  "Hi there! I'm curious, which exchange program were you a part of?",
  "Hello! I'd love to know, what's the most memorable experience from your exchange program?",
  'Hi! Which city were you in during your exchange program?',
  'Hey! What was the biggest cultural shock you experienced during your program?',
  "Hello, what's one thing you learned from your exchange program that still impacts your life?",
  'Hi, did your experience from the exchange program influence your career choice?',
  'Hey! Which year did you participate in the exchange program?',
  'Hello! Did you make any lifelong friends during your exchange program?',
  "Hi there! I'm interested, did you keep up with any habits you picked up during your exchange program?",
  'Hey! Did your exchange program alter your worldview in any significant way?',
  'Hi, what were your favorite local foods during your exchange program?',
  "Hello! What's one thing you'd tell someone about to embark on an exchange program?",
  'Hey there! How has your exchange program influenced your current life?',
  'Hi! Have you been back to the US since your exchange program?',
  'Hello! Can you share an unforgettable moment from your exchange program?',
  'Hey! What was your favorite place you visited while on the exchange program?',
  'Hello, were there any challenges you had to overcome during your exchange program?',
  'Hi there! What were some of your favorite classes or lectures during the exchange program?',
  'Hey! If you could go back and re-experience your exchange program, what would you do differently?',
  'Hi! What was your favorite event or festival you attended during your exchange program?',
  'Hello! Did you have a favorite teacher or mentor during your exchange program?',
  'Hey there! How did your family react to your experiences and stories from the exchange program?',
  "Hi! What's the funniest story from your exchange program?",
  'Hello! How did you adjust to the new lifestyle during your exchange program?',
  'Hey! Have you kept in touch with any of your fellow exchange program participants?',
  'Hi there! What was your initial reaction when you first arrived in the US?',
  'Hello! Which part of American culture surprised you the most?',
  'Hey! What were some of your favorite American traditions you discovered during your program?',
  'Hi! Did you have a favorite American dish?',
  "Hello! What's the most important lesson you learned during your exchange program?",
  'Hey there! How did you communicate with your family back home during your exchange program?',
  "Hi! What's one thing you wish you knew before your exchange program?",
  'Hello! How did you balance school and exploring your new environment during your program?',
  'Hey! Can you share any tips for future exchange program participants?',
  'Hi there! Were there any local customs or traditions that you brought back home with you?',
  'Hello! How did your exchange program affect your perspective on international relations?',
  'Hey! What was your most memorable road trip or excursion during your exchange program?',
  'Hi! Did you pick up any American slang or phrases during your program?',
  'Hello! How different was your everyday routine in the US compared to your home country?',
  'Hey there! Can you share a funny incident involving a cultural misunderstanding during your exchange program?',
  'Hi! What was your biggest takeaway from living with a host family, if you did so?',
  'Hello! How has your exchange program helped you in your personal growth?',
  'Hey! Did your exchange program experience inspire you to explore more countries?',
  'Hi there! Did you develop any new hobbies or interests during your exchange program?',
  "Hello! What's one American place or landmark that left a lasting impression on you?",
  "Hey! If you could relive your exchange program, what's one thing you wouldn't change?",
  'Hi! How did your exchange program influence your understanding of American politics?',
  'Hello! How did you overcome language barriers during your exchange program?',
  "Hey there! What's one American book, film, or song that you discovered and loved during your exchange program?",
  "Hi! Did you experience any 'homesickness' during your exchange program? How did you deal with it?",
  'Hello! How did your exchange program help you develop leadership skills?',
  'Hey! Did you have any surprising experiences with American wildlife?',
  "Hi there! What's one piece of advice you'd give to someone considering an exchange program?",
  "Hello! What's one American habit you found strange or fascinating?",
  "Hey! What's one American city or state you wish you had the chance to visit during your exchange program?",
  'Hi! How did the climate in the US compare to your home country during your exchange program?',
  'Hello! How did you experience American holidays, like Thanksgiving or Independence Day, during your exchange program?',
  "Hey there! What's your favorite memory involving American music or entertainment during your exchange program?",
  "Hi! What's the most challenging thing about adjusting back to your home country after the exchange program?",
  'Hello! How did your experience in the US compare with your expectations prior to the exchange program?',
  "Hey! What's one American stereotype that was debunked for you during your exchange program?",
  'Hi there! How did you handle the differences in academic system during your exchange program?',
  'Hello! Was there a particular event or person that deeply impacted you during your exchange program?',
  'Hey! Can you share any interesting encounters with American wildlife during your exchange program?',
  'Hi! What was your favorite season in the US and why?',
  'Hello! How did you perceive the sense of community in the US during your exchange program?',
  "Hey there! What's the most valuable thing you brought back from your exchange program?",
  'Hi! Did you take part in any extra-curricular activities or clubs during your exchange program?',
  'Hello! Can you share any fun memories from your prom night, if you attended one?',
  'Hey! What are your thoughts on the US education system after your exchange program?',
  'Hi there! What is the biggest difference between your home country and the US that you noticed?',
  'Hello! How did you keep a record of your experiences during your exchange program (diary, blog, vlog, etc.)?',
  "Hey! What's the most adventurous thing you did during your exchange program?",
  'Hi! Did you have any favorite American TV shows or movies during your exchange program?',
  'Hello! How did the exchange program affect your future educational decisions?',
  "Hey there! What's your favorite American sports memory, if you attended any games?",
  'Hi! Can you share a story of a typical day in your life during the exchange program?',
  'Hello! How did you navigate the transportation system in the US during your exchange program?',
  'Hey! Did you have a favorite local hangout spot during your exchange program?',
  'Hi there! Did you visit any national parks or natural attractions during your exchange program?',
  "Hello! What's one thing from your home country that you missed the most during your exchange program?",
  'Hey! Did you have the opportunity to share your culture with others during the exchange program?',
  'Hi! Did you have a favorite professor or teacher during your exchange program?',
  'Hello! Can you share a heartwarming story from your exchange program?',
  'Hey there! Did you have any surprising interactions with American healthcare during your exchange program?',
  'Hi! How did you celebrate your birthday, if it occurred during your exchange program?',
  'Hello! How did your exchange program influence your views on diversity and inclusion?',
  'Hey! Did you experience snow for the first time during your exchange program?',
  "Hi there! What's one American event or festival that you wish your home country had too?",
  'Hello! If you had to describe your exchange program experience in three words, what would they be?',
  "Hey! What's one thing you wish you could bring to the US from your home country?",
  "Hi! What's one thing about your exchange program that you think would surprise others?",
  'Hello! What was your first impression of American schools when you started your exchange program?',
  'Hey there! How has your exchange program influenced your professional life?',
  'Hi! Can you share a story of a meaningful interaction you had with someone during your exchange program?',
  'Hello! How did the exchange program impact your communication skills?',
];

export default icebreakers;
