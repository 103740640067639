import { HStack, Icon, Text, Pressable } from '@gluestack-ui/themed';
import {
  HeartHandshakeIcon,
  HelpingHandIcon,
  ConstructionIcon,
  FlameIcon,
} from 'lucide-react-native';
import { useCreate } from '@refinedev/core';
import { useCallback } from 'react';
import GrowAndSlide from './GrowAndSlide';
import { groupBy } from 'lodash';
import OutsidePressHandler from 'react-native-outside-press';

const REACTION = {
  promote: {
    key: 'promote',
    text: 'I promote this',
    color: '$danger500',
    icon: FlameIcon,
  },
  encourage: {
    key: 'encourage',
    text: 'I encourage this',
    color: '$primary500',
    icon: HelpingHandIcon,
  },
  help: {
    key: 'help',
    text: 'I can help',
    color: '$success500',
    icon: HeartHandshakeIcon,
  },
  work: {
    key: 'work',
    text: 'Needs some work',
    color: '$warning500',
    icon: ConstructionIcon,
  },
};

const REACTION_ORDER = [
  REACTION.promote,
  REACTION.encourage,
  REACTION.help,
  REACTION.work,
];

export const Reactions = ({ id, refetch, resource, reactions = [] }) => {
  const objectId = resource === 'postReactions' ? 'postId' : 'commentId';
  // const { data: identity } = useGetIdentity({  });
  // const navigation = useNavigation();
  // const [comment, setComment] = useState('')
  // const [commentAll, setCommentAll] = useState(false)
  // const [commentVisible, setCommentVisible] = useState(false)

  const { mutate } = useCreate();

  const typedReactions = groupBy(reactions, 'reaction');

  const handleReact = useCallback(
    (reaction, animate) => {
      mutate(
        {
          resource,
          values: {
            [objectId]: id,
            createdAt: new Date(),
            reaction,
          },
          successNotification: () => {
            return {
              message: `Your reaction was successfully submitted.`,
              type: 'success',
            };
          },
        },
        {
          onSuccess: () => {
            refetch();
            animate();
          },
        }
      );
    },
    [objectId, resource, id]
  );

  // const allComments = getAllPagesData(comments);

  // const { data: profiles, isLoading: profilesIsLoading } = useMany({
  //   resource: "profiles",
  //   ids: allComments?.map(({ userId }) => userId),
  //   enabled: !commentsIsLoading && allComments && Array.isArray(allComments)
  // });

  return (
    <GrowAndSlide
      renderInitialContent={({ animateContent, inFocus }) => {
        const reacts = REACTION_ORDER.filter(({ key }) =>
          Object.keys(typedReactions).includes(key)
        );
        return (
          <Pressable disabled={!inFocus} onPress={animateContent}>
            <HStack>
              {reacts.length > 0
                ? reacts.map(({ icon, key, color }) => (
                    <HStack key={key} alignItems="center" mr="$4">
                      <Icon color={color} mr="$3" as={icon} />
                      <Text color={color}>{typedReactions[key].length}</Text>
                    </HStack>
                  ))
                : REACTION_ORDER.map(({ icon, key }) => (
                    <HStack key={key} alignItems="center" mr="$1">
                      <Icon as={icon} />
                    </HStack>
                  ))}
            </HStack>
          </Pressable>
        );
      }}
      renderTargetContent={({ animateContent, inFocus }) => (
        <OutsidePressHandler
          disabled={!inFocus}
          onOutsidePress={animateContent}
        >
          <HStack>
            {REACTION_ORDER.map(({ icon, key, color }) => (
              <Pressable
                key={key}
                alignItems="center"
                mr="$4"
                onPress={() => handleReact(key, animateContent)}
              >
                <Icon color={color} mr="$3" as={icon} size="xl" />
              </Pressable>
            ))}
          </HStack>
        </OutsidePressHandler>
      )}
      initialHeight={22}
      targetHeight={30}
    />
  );
};
