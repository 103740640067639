import React, { useRef, useState } from 'react';
import { View, useWindowDimensions } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withSpring,
  withTiming,
  runOnJS,
} from 'react-native-reanimated';
import { PanGestureHandler } from 'react-native-gesture-handler';
import { Icon, Text, Pressable } from '@gluestack-ui/themed';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react-native';

const Swiper = ({ style, items, renderItem }) => {
  const { width } = useWindowDimensions();
  const translateX = useSharedValue(0);
  const currentIndex = useRef(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const prevItem = () => {
    if (activeIndex > 0) itemChange(activeIndex - 1);
  };

  const nextItem = () => {
    if (activeIndex < items.length - 1) itemChange(activeIndex + 1);
  };

  const itemChange = (index) => {
    translateX.value = withTiming(-index * width);
    setActiveIndex(index);
    currentIndex.current = index;
  };

  const safeSetIndex = (index) => {
    setActiveIndex(index);
  };

  const gestureHandler = useAnimatedGestureHandler({
    onStart: (_, ctx) => {
      ctx.startX = translateX.value;
    },
    onActive: (event, ctx) => {
      translateX.value = ctx.startX + event.translationX;
    },
    onEnd: (event) => {
      let nextIndex = Math.round(-translateX.value / width);

      if (event.velocityX < 0 && nextIndex < items.length - 1) {
        nextIndex += 1;
      } else if (event.velocityX > 0 && nextIndex > 0) {
        nextIndex -= 1;
      }

      nextIndex = Math.max(0, Math.min(nextIndex, items.length - 1));
      currentIndex.current = nextIndex;
      translateX.value = withSpring(-nextIndex * width);
      runOnJS(safeSetIndex)(nextIndex);
    },
  });

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: translateX.value }],
    };
  });

  return (
    <View style={[style, { position: 'relative' }]}>
      <PanGestureHandler onGestureEvent={gestureHandler}>
        <Animated.View style={[style, animatedStyle]}>
          {items.map((item) => renderItem({ item, width }))}
        </Animated.View>
      </PanGestureHandler>
      <View
        style={{
          zIndex: 2000,
          paddingHorizontal: 16,
          position: 'absolute',
          bottom: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 40,
          width: '100%',
        }}
      >
        <Pressable onPress={prevItem}>
          <Icon as={ChevronLeftIcon} size="xl" />
        </Pressable>
        <Text>{`${activeIndex + 1}/${items.length}`}</Text>
        <Pressable onPress={nextItem}>
          <Icon as={ChevronRightIcon} size="xl" />
        </Pressable>
      </View>
    </View>
  );
};

export default Swiper;
