import { useState, useEffect } from 'react';
import { useInfiniteList } from '@refinedev/core';
import { useGo } from '../hooks/useNav';
import { getAllPagesData } from '../utils/data';
import { Box } from '@gluestack-ui/themed';
import { Deck } from '../components/layout/Deck';

export default function Match() {
  const [items, setItems] = useState([]);
  const go = useGo();
  const {
    data: profiles,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteList({
    resource: 'profiles',
    pagination: {
      pageSize: 20,
    },
    filters: [
      {
        operator: 'or',
        value: [
          {
            field: 'canHelp',
            operator: 'nnull',
            value: null,
          },
          {
            field: 'needHelp',
            operator: 'nnull',
            value: null,
          },
        ],
      },
    ],
  });

  const allProfiles = getAllPagesData(profiles);

  useEffect(() => {
    if (allProfiles?.length && !items?.length) setItems(allProfiles);
  }, [allProfiles?.length, items?.length]);

  return (
    <Box flex={1} p="$4" h="$full">
      <Deck items={items} setItems={setItems} go={go} />
    </Box>
  );
}
