import React, { useState, useCallback } from 'react';
import { ScrollView } from 'react-native';
import { Image, Box, Heading, Divider } from '@gluestack-ui/themed';
import { MailIcon } from 'lucide-react-native';
import { UiButton, UiInput, UiLink } from '../../components/ui';
import { useForgotPassword, useNotification } from '@refinedev/core';

const Forgot = (props) => {
  const [email, setEmail] = useState('');
  const { mutate: forgotPassword } = useForgotPassword();
  const { open, close } = useNotification();

  const handeleError = useCallback(
    (error) => {
      open?.({
        key: 'register-error',
        type: 'error',
        message: 'Error',
        description: error.message,
      });
    },
    [open]
  );

  const resetPassword = useCallback(() => {
    close?.('register-error');
    forgotPassword(
      { email },
      {
        onSuccess: (data) => {
          if (!data.success) return handeleError(new Error(data.error));

          open?.({
            type: 'success',
            message: 'Success',
            description: 'This is a success message',
          });
        },
        onError: handeleError,
      }
    );
  }, [email, close, open, forgotPassword, handeleError]);

  return (
    <Box px="$8" flex={1}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
      >
        <Image
          h="$10"
          mb="$5"
          // eslint-disable-next-line no-undef
          source={require('../../../assets/bclogo.png')}
          resizeMode="contain"
        />
        <Heading size="2xl" textAlign="center" mb="$5">
          Forgot Password
        </Heading>
        <UiInput
          autoCapitalize={'none'}
          autoComplete={'email'}
          autoCorrect={false}
          inputMode="email"
          mb="$5"
          borderRadius="$xl"
          size="xl"
          icon={MailIcon}
          placeholder="Email"
          onChangeText={(value) => setEmail(value)}
          value={email}
        />
        <UiButton
          mb="$5"
          action="negative"
          onPress={resetPassword}
          title="RESET PASSWORD"
        />
        <Divider w="$full" mb="$5" />
        <Box display="flex" w="$full" alignItems="center">
          <UiLink to="/login">Sign in</UiLink>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default Forgot;
