import React, { memo } from 'react';
import TabContainer from './components/layout/TabContainer';
import { VStack, Pressable, Icon, Text } from '@gluestack-ui/themed';
import {
  PlusSquareIcon,
  MessageCircleIcon,
  HomeIcon,
  UsersIcon,
  HeartHandshakeIcon,
} from 'lucide-react-native';
import { useGo } from './hooks/useNav';

const TabItem = ({ title, icon, active, to }) => {
  const go = useGo();
  return (
    <Pressable w={52} h={52} onPress={() => go({ to })}>
      <VStack flex={1} alignItems="center" justifyContent="center">
        <Icon size="xl" as={icon} color={active ? '$primary500' : 'gray'} />
        <Text size="xs" color={active ? '$primary500' : 'gray'}>
          {title}
        </Text>
      </VStack>
    </Pressable>
  );
};

export default function Tabmenu({ state, navigation }) {
  return (
    <TabContainer px="$5">
      <TabItem
        title="Home"
        icon={HomeIcon}
        to={'/feed'}
        active={state.index === 0}
      />
      <TabItem
        title="Match"
        icon={HeartHandshakeIcon}
        to={'/match'}
        active={state.index === 1}
      />
      <TabItem title="Post" icon={PlusSquareIcon} to={'/post'} />
      <TabItem
        title="Network"
        icon={UsersIcon}
        to={'/network'}
        active={state.index === 2}
      />
      <TabItem
        title="Message"
        icon={MessageCircleIcon}
        to={'/chats'}
        active={state.index === 3}
      />
    </TabContainer>
  );
}
