import {
  Box,
  Image,
  VStack,
  HStack,
  Icon,
  Heading,
  Text,
  Center,
  Pressable,
} from '@gluestack-ui/themed';
import { UiAvatar } from '../ui';
import Card from './Card';
import {
  UserPlusIcon,
  HeartHandshakeIcon,
  RedoIcon,
} from 'lucide-react-native';

export const ChatListItem = ({ myId, item, onAction }) => {
  const profile = item.profiles.find(({ id }) => id !== myId);
  return (
    <Pressable onPress={() => onAction(item.id)}>
      <Card w="$full" borderRadius="$none" px="$3" py="$2">
        <HStack w="$full">
          <HStack flexGrow={1} alignItems="center">
            <UiAvatar size="sm" mr="$3" profile={profile} />
            <Heading size="sm">{profile?.displayName}</Heading>
          </HStack>
        </HStack>
      </Card>
    </Pressable>
  );
};
