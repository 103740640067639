import { useCallback } from 'react';
import * as ImagePicker from 'expo-image-picker';
import uploadImageAsync from '../lib/uploadImageAsync';

export default function useImagePicker(folder) {
  const pickImage = useCallback(async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.canceled) {
      return uploadImageAsync(result.assets[0], folder);
    }
  }, []);

  return pickImage;
}
