import {
  Box,
  VStack,
  HStack,
  Icon,
  Heading,
  Text,
  Badge,
  Pressable,
  Center,
  Menu,
} from '@gluestack-ui/themed';
import Card from './Card';
import { UiAvatar, UiButton, UiInput } from '../ui';
import { Image } from 'expo-image';
import TimeAgo from '@andordavoti/react-native-timeago';
import {
  ThumbsUpIcon,
  MessageSquareIcon,
  BookmarkPlusIcon,
  HeartHandshakeIcon,
  HelpingHandIcon,
  ConstructionIcon,
  FlameIcon,
} from 'lucide-react-native';
import { useNavigation } from '@react-navigation/native';
import {
  useInfiniteList,
  useCreate,
  useMany,
  useGetIdentity,
} from '@refinedev/core';
import { useState, useCallback } from 'react';
import Background from './Background';
import { Reactions } from './Reactions';
import { getAllPagesData } from '../../utils/data';

export const PostCard = ({
  refetch,
  item: {
    id,
    body,
    createdAt,
    tags = [],
    pictures = [],
    comments = [],
    postReactions = [],
    profiles = {},
    ...props
  },
}) => {
  const { data: identity } = useGetIdentity({});
  const navigation = useNavigation();
  const [comment, setComment] = useState('');
  const [commentAll, setCommentAll] = useState(false);
  const [commentVisible, setCommentVisible] = useState(false);

  const { mutate } = useCreate();

  const handleSubmitComment = useCallback(() => {
    mutate(
      {
        resource: 'comments',
        values: {
          postId: id,
          createdAt: new Date(),
          comment,
        },
        successNotification: () => {
          return {
            message: `Your comment was successfully submitted.`,
            type: 'success',
          };
        },
      },
      {
        onSuccess: () => {
          setComment('');
          setCommentVisible(false);
          refetch();
        },
      }
    );
  }, [comment, id]);

  return (
    <Card>
      <VStack py="$3" w="$full">
        <HStack w="$full" px="$3" alignItems="center">
          <HStack flexGrow={1} alignItems="center">
            <UiAvatar size="sm" mr="$3" profile={profiles} />
            <Heading size="sm">{profiles?.displayName}</Heading>
          </HStack>
          <Text size="sm">
            <TimeAgo
              dateTo={
                new Date(
                  createdAt?._seconds ? createdAt._seconds * 1000 : createdAt
                )
              }
            />
          </Text>
        </HStack>
        <Box p="$3">
          <Text>{body}</Text>
        </Box>
        {tags.length > 0 && (
          <HStack py="$1" px="$3">
            {tags.map((tag) => (
              <Badge key={tag} mr="$3">
                <Badge.Text>{`#${tag}`}</Badge.Text>
              </Badge>
            ))}
          </HStack>
        )}
        {pictures.length > 0 && (
          <Box mb="$1" mt="$3">
            <HStack w="$full" h="$32">
              <Pressable
                flex={1}
                onPress={() =>
                  navigation.navigate('Pictures', {
                    images: pictures,
                    initialIndex: 0,
                  })
                }
              >
                <Image
                  style={{ flex: 1, width: '100%' }}
                  contentFit="cover"
                  contentPosition={'center'}
                  source={pictures[0]}
                />
              </Pressable>
              {pictures.length > 1 && (
                <Pressable
                  h="$32"
                  w="$32"
                  ml="$2"
                  position="relative"
                  onPress={() =>
                    navigation.navigate('Pictures', {
                      images: pictures,
                      initialIndex: 1,
                    })
                  }
                >
                  <Image
                    style={{ flex: 1, width: '100%' }}
                    contentFit="cover"
                    contentPosition={'center'}
                    source={pictures[1]}
                    blurRadius={3}
                  />
                  <Box
                    h="$32"
                    w="$32"
                    position="absolute"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bg="rgba(0,0,0,0.6)"
                  >
                    <Text color="$white" size="4xl">{`+${
                      pictures.length - 1
                    }`}</Text>
                  </Box>
                </Pressable>
              )}
            </HStack>
          </Box>
        )}
        <HStack w="$full" p="$3" alignItems="center">
          <HStack flexGrow={1} alignItems="center">
            <Reactions
              id={id}
              refetch={refetch}
              resource="postReactions"
              reactions={postReactions}
            />
          </HStack>
          <Pressable>
            <Icon as={BookmarkPlusIcon} />
          </Pressable>
        </HStack>
        {comments?.length > 0 && (
          <VStack w="$full" px="$3">
            {comments
              .sort(
                (a, b) =>
                  new Date(
                    a.createdAt?._seconds
                      ? a.createdAt._seconds * 1000
                      : a.createdAt
                  ) -
                  new Date(
                    b.createdAt?._seconds
                      ? b.createdAt._seconds * 1000
                      : b.createdAt
                  )
              )
              .map(
                (
                  { id, profiles, comment, createdAt, commentReactions },
                  index
                ) => {
                  return (
                    <Background
                      key={id}
                      w="$full"
                      mb="$3"
                      borderRadius="$md"
                      p="$2"
                    >
                      <VStack px="$3">
                        <HStack w="$full" mb="$2" alignItems="center">
                          <HStack flexGrow={1} alignItems="center">
                            <UiAvatar size="xs" mr="$3" profile={profiles} />
                            <Heading size="xs">{profiles?.displayName}</Heading>
                          </HStack>
                          <Text size="xs">
                            <TimeAgo
                              dateTo={
                                new Date(
                                  createdAt?._seconds
                                    ? createdAt._seconds * 1000
                                    : createdAt
                                )
                              }
                            />
                          </Text>
                        </HStack>
                        <Text>{comment}</Text>
                        <HStack w="$full" py="$3" alignItems="center">
                          <HStack flexGrow={1} alignItems="center">
                            <Reactions
                              id={id}
                              refetch={refetch}
                              resource="commentReactions"
                              reactions={commentReactions}
                            />
                          </HStack>
                        </HStack>
                      </VStack>
                    </Background>
                  );
                }
              )}
          </VStack>
        )}
        <Center px="$3">
          {commentVisible ? (
            <>
              <UiInput
                multiline
                h="$24"
                w="$full"
                value={comment}
                onChangeText={setComment}
              />
              <HStack w="$full" justifyContent="space-between">
                <UiButton
                  title="Cancel"
                  action="secondary"
                  variant="link"
                  onPress={() => {
                    setCommentVisible(false);
                    setComment('');
                  }}
                />
                <UiButton
                  title="Send"
                  variant="link"
                  onPress={handleSubmitComment}
                />
              </HStack>
            </>
          ) : (
            <UiButton
              title="Comment"
              size="sm"
              variant="link"
              onPress={() => setCommentVisible(true)}
            />
          )}
        </Center>
      </VStack>
    </Card>
  );
};
