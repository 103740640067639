import React, { useState, useCallback } from 'react';
import { ScrollView } from 'react-native';
import { Image, Text, Box, Heading, Divider } from '@gluestack-ui/themed';
import { MailIcon, LockIcon, EyeIcon, EyeOffIcon } from 'lucide-react-native';
import { UiButton, UiInput, UiLink } from '../../components/ui';
import { useLogin, useNotification } from '@refinedev/core';
import { useGo } from '../../hooks/useNav';

const SignIn = (props) => {
  //const [passwordShow, setPasswordShow] = useState(true);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [busy, setBusy] = useState(false);
  const { mutate: login } = useLogin({});
  const { open, close } = useNotification();
  const go = useGo();

  const handeleError = useCallback(
    (error) => {
      open?.({
        key: 'register-error',
        type: 'error',
        message: 'Error',
        description: error.message,
      });
    },
    [open]
  );

  // const handndleShowPassword = useCallback(() => {
  //   setPasswordShow((state) => !state);
  // }, []);

  const loginUser = useCallback(() => {
    setBusy(true);
    close?.('login-error');
    login(
      { email, password: pass },
      {
        onSuccess: (data) => {
          if (!data.success) return handeleError(new Error(data.error));

          open?.({
            type: 'success',
            message: 'Success',
            description: 'This is a success message',
          });
          setTimeout(() => {
            setBusy(false);
            go({ to: '/feed' });
          }, 2000);
        },
        onError: handeleError,
        onSettled: () => {
          setBusy(false);
        },
      }
    );
  }, [email, pass, login, open, close, handeleError, go]);

  return (
    <Box px="$8" flex={1}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
      >
        <Image
          h="$10"
          mb="$5"
          // eslint-disable-next-line no-undef
          source={require('../../../assets/bclogo.png')}
          resizeMode="contain"
          alt="logo"
        />
        <Heading size="2xl" textAlign="center" mb="$5">
          Sign In
        </Heading>
        <UiInput
          autoCapitalize={'none'}
          autoComplete={'email'}
          autoCorrect={false}
          inputMode="email"
          mb="$5"
          borderRadius="$xl"
          size="xl"
          icon={MailIcon}
          placeholder="Email"
          onChangeText={(value) => setEmail(value)}
          value={email}
        />
        <UiInput
          autoCapitalize={'none'}
          autoComplete={'current-password'}
          autoCorrect={false}
          mb="$5"
          borderRadius="$xl"
          size="xl"
          icon={LockIcon}
          //iconRight={passwordShow ? EyeIcon : EyeOffIcon}
          placeholder="Password"
          type="password"
          onChangeText={(value) => setPass(value)}
          value={pass}
        />
        <Box mb="$5" display="flex" w="$full" alignItems="flex-end">
          <UiLink to="/forgot-password">Forgot Password</UiLink>
        </Box>
        <UiButton
          mb="$5"
          onPress={loginUser}
          title="SIGN IN"
          isLoading={busy}
        />
        <Divider w="$full" mb="$5" />
        <Box display="flex" w="$full" alignItems="center">
          <Text>Don’t have an account?</Text>
          <UiLink to="/register">Sign up</UiLink>
        </Box>
      </ScrollView>
    </Box>
  );
};

export default SignIn;
