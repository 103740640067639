import React from 'react';
import { NotificationProvider } from '@refinedev/core';
import {
  Toast,
  ToastTitle,
  ToastDescription,
  VStack,
} from '@gluestack-ui/themed';

export const notificationProvider = (toast): NotificationProvider => {
  return {
    open: ({
      key,
      message,
      type,
      description,
      undoableTimeout,
      cancelMutation,
    }) => {
      toast.show({
        id: key,
        placement: 'top',
        render: ({ id }) => {
          return (
            <Toast
              nativeId={id}
              action={type === 'progress' ? 'info' : type}
              variant="accent"
            >
              <VStack space="xs">
                <ToastTitle>{message}</ToastTitle>
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </VStack>
            </Toast>
          );
        },
      });
    },
    close: (key) => toast.close(key),
  };
};
