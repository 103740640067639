import React, { useState } from 'react';
import { View, Text, FlatList } from 'react-native';
import {
  useInfiniteList,
  useCreate,
  useCreateMany,
  useGetIdentity,
} from '@refinedev/core';
import { useGo } from '../hooks/useNav';
import { getAllPagesData } from '../utils/data';
import { ProfileListItem } from '../components/layout/ProfileListItem';
import Background from '../components/layout/Background';

//TODO: PAGINATION!

export default function Network() {
  const { mutate: create } = useCreate();
  const { mutate: createMany } = useCreateMany();
  const { data: identity } = useGetIdentity();
  const go = useGo();

  const {
    data: profiles,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
    refetch,
  } = useInfiniteList({
    resource: 'profiles',
    pagination: {
      pageSize: 20,
    },
    meta: {
      select: 'id, avatarUrl, displayName, chats(id)',
    },
  });

  const handleAction = (id) => {
    go({ to: `/profile/${id}` });
  };

  return (
    <FlatList
      isLoading={isLoading}
      refreshing={isRefetching}
      onRefresh={refetch}
      ItemSeparatorComponent={() => <Background h={1} />}
      data={getAllPagesData(profiles).filter(({ id }) => id !== identity?.id)}
      renderItem={({ item }) => (
        <ProfileListItem key={item.id} item={item} onAction={handleAction} />
      )}
    />
  );
}
