import React, { useState, useCallback } from 'react';
import { ScrollView } from 'react-native';
import { useOne, useGetIdentity, useUpdate } from '@refinedev/core';
import Loading from './Loading';
import {
  Heading,
  VStack,
  HStack,
  Text,
  Box,
  Icon,
  Pressable,
} from '@gluestack-ui/themed';
import { UiAvatar, UiInput } from '../components/ui';
import Background from '../components/layout/Background';
import useImagePicker from '../hooks/useImagePicker';
import { EditIcon, SaveIcon } from 'lucide-react-native';
import useUser from '../hooks/useUser';
import Screen from '../Screen';

const PressableHeader = ({ isEdited, onPress, title }) => (
  <Pressable onPress={onPress} mb="$3">
    <HStack alignItems="center">
      <Icon
        as={isEdited ? SaveIcon : EditIcon}
        size="lg"
        mr="$3"
        color={isEdited ? '$success500' : '$primary500'}
      />
      <Heading size="sm">{title}</Heading>
    </HStack>
  </Pressable>
);

const TextEditableField = ({
  isLong,
  onPress,
  title,
  field,
  data,
  onUpdate,
}) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(data[field] || '');

  const handleEdit = useCallback(() => setEdit(true), []);

  const handleFinish = useCallback(() => {
    onUpdate({ [field]: value });
    setEdit(false);
  }, [value]);

  return (
    <Box mb="$5">
      <PressableHeader
        onPress={edit ? handleFinish : handleEdit}
        isEdited={edit}
        title={title}
      />
      {edit ? (
        <UiInput
          multiline={isLong}
          value={value}
          onChangeText={setValue}
          {...(isLong ? { h: '$24' } : {})}
        />
      ) : (
        <Text size={isLong ? 'md' : 'xl'}>{data[field] || ''}</Text>
      )}
    </Box>
  );
};

export default function ProfileEdit() {
  const pickImage = useImagePicker('avatars');
  const { data: user, isLoading } = useUser({ liveMode: 'auto' });
  const { mutate } = useUpdate();

  const updateField = useCallback(
    (values) => {
      if (user?.data?.id)
        mutate({
          resource: 'profiles',
          values,
          id: user?.data?.id,
          successNotification: () => {
            return {
              message: `Your profile was successfully updated.`,
              type: 'success',
            };
          },
        });
    },
    [user?.data?.id]
  );

  const handleAvatarPress = async () => {
    const avatarUrl = await pickImage();
    updateField({ avatarUrl });
  };

  if (isLoading || !user?.data?.id) return <Loading />;

  return (
    <Screen hasBack>
      <Background flex={1}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <VStack px="$3" py="$5">
            <Box mb="$5">
              <PressableHeader onPress={handleAvatarPress} title={'Avatar'} />
              <UiAvatar size="xl" profile={user.data} />
            </Box>
            <TextEditableField
              onUpdate={updateField}
              title="Display Name"
              field={'displayName'}
              data={user.data}
            />
            <TextEditableField
              onUpdate={updateField}
              title="Username"
              field={'slug'}
              data={user.data}
            />
            <TextEditableField
              onUpdate={updateField}
              title="Email"
              field={'email'}
              data={user.data}
            />
            <TextEditableField
              onUpdate={updateField}
              title="Phone Number"
              field={'phone'}
              data={user.data}
            />
            <TextEditableField
              onUpdate={updateField}
              title="LinkedIn"
              field={'linkedin'}
              data={user.data}
            />
            <TextEditableField
              isLong
              onUpdate={updateField}
              title="Bio"
              field={'bio'}
              data={user.data}
            />
            <TextEditableField
              isLong
              onUpdate={updateField}
              title="Need Help With"
              field={'needHelp'}
              data={user.data}
            />
            <TextEditableField
              isLong
              onUpdate={updateField}
              title="Can Help With"
              field={'canHelp'}
              data={user.data}
            />
          </VStack>
        </ScrollView>
      </Background>
    </Screen>
  );
}
