import React, { useCallback, useMemo } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useBack } from './hooks/useNav';
import { XIcon } from 'lucide-react-native';
import { UiButton } from './components/ui';

export default function Screen({
  children,
  hasBack,
  title,
  headerRight,
  headerLeft,
}) {
  const navigation = useNavigation();
  const back = useBack();

  const navigationOptions = useMemo(() => {
    let options = {};
    if (headerLeft || hasBack)
      options.headerLeft = hasBack
        ? () => (
            <UiButton
              mx="$3"
              size="xl"
              onPress={() => back()}
              action="secondary"
              variant="outline"
              borderWidth="$0"
              icon={XIcon}
            />
          )
        : headerLeft;
    if (headerRight) options.headerRight = headerRight;
    if (title) options.title = title;
    return options;
  }, [back, hasBack, headerLeft, headerRight, title]);

  useFocusEffect(
    useCallback(() => {
      navigation.setOptions(navigationOptions);
    }, [navigationOptions, navigation])
  );

  return children;
}
