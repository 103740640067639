import { useCallback, memo } from 'react';
import {
  Heading,
  VStack,
  Divider,
  Center,
  HStack,
  Switch,
  Icon,
} from '@gluestack-ui/themed';
import Background from './components/layout/Background';
import { UiAvatar, UiButton } from './components/ui';
import {
  SunIcon,
  MoonIcon,
  UserIcon,
  CogIcon,
  LogOutIcon,
} from 'lucide-react-native';
import { useLogout } from '@refinedev/core';
import { useGo } from './hooks/useNav';
import useUser from './hooks/useUser';
import useAsyncStorage from './hooks/useAsyncStorage';
import { Pressable } from 'react-native';

export default function Sidemenu({ navigation }) {
  const { mutate: logout } = useLogout({});
  const { data: user } = useUser();
  const go = useGo();
  const [colorMode, setColorMode] = useAsyncStorage('colorMode');

  const handleNavigate = useCallback((to) => {
    navigation.closeDrawer();
    go({ to });
  }, []);
  return (
    <Background flex={1}>
      <VStack px="$3" py="$5" flex={1}>
        <VStack flexGrow={1}>
          <Center>
            <HStack alignItems="center" mb="$5">
              <Pressable onPress={() => setColorMode('light')}>
                <Icon size="xl" mr="$2" as={SunIcon} />
              </Pressable>
              <Switch
                value={colorMode === 'dark'}
                onToggle={() =>
                  setColorMode(colorMode === 'dark' ? 'light' : 'dark')
                }
              />
              <Pressable onPress={() => setColorMode('dark')}>
                <Icon size="xl" ml="$2" as={MoonIcon} />
              </Pressable>
            </HStack>
            <UiAvatar size="lg" profile={user?.data} />
            <Heading>{user?.data?.displayName}</Heading>
          </Center>
          <Divider my="$5" px="$5" />
          <UiButton
            icon={UserIcon}
            title="Profile"
            action="secondary"
            variant="outline"
            borderWidth="$0"
            onPress={() => handleNavigate('/edit-profile')}
          />
          {/* <UiButton
            icon={CogIcon}
            title="Settings"
            action="secondary"
            variant="outline"
            borderWidth="$0"
            onPress={() => handleNavigate('/settings')}
          /> */}
        </VStack>
        <UiButton
          icon={LogOutIcon}
          title="Logout"
          action="secondary"
          variant="outline"
          borderWidth="$0"
          onPress={() => logout()}
        />
      </VStack>
    </Background>
  );
}
