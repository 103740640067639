import React from 'react';
import { Avatar, AvatarImage, AvatarFallbackText } from '@gluestack-ui/themed';

export const UiAvatar = ({ profile = {}, ...props }) => {
  const { displayName = 'Anon', avatarUrl = null } = profile;
  return (
    <Avatar {...props}>
      {avatarUrl ? (
        <AvatarImage bg="$white" source={{ uri: avatarUrl }} />
      ) : (
        <AvatarFallbackText>
          {displayName ? displayName[0] : 'X'}
        </AvatarFallbackText>
      )}
    </Avatar>
  );
};
