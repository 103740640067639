import React, { useState, useCallback, useEffect } from 'react';
import { FlatList } from 'react-native';
import { useOne, useGetIdentity, useCreate } from '@refinedev/core';
import Loading from './Loading';
import {
  Heading,
  VStack,
  HStack,
  Text,
  Box,
  Icon,
  Pressable,
  Image,
} from '@gluestack-ui/themed';
import { UiButton, UiInput } from '../components/ui';
import Background from '../components/layout/Background';
import useImagePicker from '../hooks/useImagePicker';
import { EditIcon, SaveIcon, ImagePlusIcon } from 'lucide-react-native';
import Screen from '../Screen';
import { useBack } from '../hooks/useNav';

export default function Post({ navigation }) {
  const pickImage = useImagePicker('posts');
  const { mutate } = useCreate();
  const [value, setValue] = useState('');
  const [pictures, setPictures] = useState([]);
  const back = useBack();

  const handleAddPicturePress = async () => {
    const avatarUrl = await pickImage();
    setPictures((current) => current.concat([avatarUrl]));
  };

  const handleSubmit = useCallback(() => {
    mutate(
      {
        resource: 'posts',
        values: {
          body: value
            .split(' ')
            .filter((v) => !v.startsWith('#'))
            .join(' '),
          createdAt: new Date(),
          tags: value
            .split(' ')
            .filter((v) => v.startsWith('#'))
            .map((v) => v.substring(1)),
          pictures,
        },
      },
      {
        onSuccess: () => {
          back();
        },
      }
    );
  }, [value, pictures.length]);

  return (
    <Screen
      hasBack
      headerRight={() => (
        <UiButton mx="$3" onPress={handleSubmit} title="Submit" />
      )}
    >
      <Background flex={1} position="relative">
        <UiInput
          flex={1}
          pt="$3"
          borderWidth="$0"
          placeholder="What's on your mind?"
          multiline
          value={value}
          onChangeText={setValue}
        />
        <HStack px="$3" w="$full" position="absolute" bottom="$3">
          <UiButton
            size="lg"
            h="$16"
            w="$16"
            borderRadius="$md"
            action="secondary"
            icon={ImagePlusIcon}
            onPress={handleAddPicturePress}
          />
          <FlatList
            flex={1}
            horizontal
            ItemSeparatorComponent={() => <Box w="$3" />}
            data={pictures}
            renderItem={({ item: uri }) => (
              <Box h="$16" w="$16" borderRadius="$md" key={uri}>
                <Image h="$16" w="$16" borderRadius="$md" source={{ uri }} />
              </Box>
            )}
          />
        </HStack>
      </Background>
    </Screen>
  );
}
