import React, { useCallback } from 'react';
import { useGo } from '../../hooks/useNav';
import { Link, LinkText } from '@gluestack-ui/themed';

export const UiLink = ({ children, to, ...props }) => {
  const go = useGo();

  const handlePress = useCallback(() => go({ to }), [to, go]);

  return (
    <Link {...props} onPress={handlePress}>
      <LinkText>{children}</LinkText>
    </Link>
  );
};
