import React, { useState, useCallback } from 'react';
import { Image } from 'expo-image';
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withSpring,
} from 'react-native-reanimated';
import { PinchGestureHandler, Swipeable } from 'react-native-gesture-handler';
import { View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import Swiper from '../components/layout/Swiper';
import Zoomer from '../components/layout/Zoomer';
import Background from '../components/layout/Background';
import Screen from '../Screen';

const Pictures = ({
  navigation,
  route: {
    params: { images = [], initialIndex = 0 },
  },
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scale = new Animated.Value(1);

  const onPinchEvent = useAnimatedGestureHandler({
    onActive: (event, context) => {
      scale.value = event.scale;
    },
    onEnd: () => {
      scale.value = withSpring(1);
    },
  });

  const swipeableHandler = (direction) =>
    direction === 'left'
      ? setCurrentIndex((currentIndex + 1) % images.length)
      : setCurrentIndex((currentIndex - 1 + images.length) % images.length);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scale.value }],
    };
  });

  return (
    <Screen hasBack>
      <Background style={{ flex: 1, height: '100%', width: '100%' }}>
        <Swiper
          style={{ flex: 1, height: '100%', flexDirection: 'row' }}
          items={images}
          renderItem={({ item, width }) => (
            <View
              key={item}
              style={{
                width,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Zoomer style={{ flex: 1, height: '100%', width: '100%' }}>
                <Image
                  style={{ flex: 1, width: '100%' }}
                  contentFit="contain"
                  contentPosition={'center'}
                  source={item}
                />
              </Zoomer>
            </View>
          )}
        />

        {/* <Swipeable onSwipeableOpen={swipeableHandler}>
        <PinchGestureHandler onGestureEvent={onPinchEvent}>
          <Animated.View style={animatedStyles}>

          </Animated.View>
        </PinchGestureHandler>
      </Swipeable> */}
      </Background>
    </Screen>
  );
};

export default Pictures;
